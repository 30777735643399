import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { MediaCard, MediaCardThumb, MediaCardBody } from "../MediaStyle"
import { getProductUrl } from "../../utils/product"
import styled from "styled-components"
import {
  DisccountedPrice,
  Divider,
  OriginPrice,
  ProductInfo,
  ProductItemToolbar,
  ProductLabelText,
  ProductName,
  ProductDesc,
  ProductPriceInfo,
  ProductToolbar,
} from "./styles"
import PriceDropIcon from "../../images/price.gif"
import SecondaryButton from "../Button/SecondaryButton"
import PrimaryOutlineButton from "../Button/PrimaryOutlineButton"
import { ProductPriceDrop } from "../ProductCard/styles"

const ProductId = styled.div`
  position: absolute;
  top: 5px;
  left: 20px;
  background: #062c44;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  z-index: 1;
`

export const DisccountTape = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
`

export const CardLabel = styled.div``

const SearchCard = ({ data }) => {
  const productUrl = getProductUrl(data)

  const renderImage = () => {
    if (data.image) {
      return <Img fluid={data.image.fluid} alt={data.title} />
    }

    if (data.imageSrc) {
      return <img src={data.imageSrc} alt={data.title} />
    }

    return null
  }

  let disccount = 0,
    finalPrice = data.price
  if (data.price > 20000) {
    disccount = 15
    finalPrice = (finalPrice * (100 - disccount)) / 100
  } else if (data.price > 10000) {
    disccount = 10
    finalPrice = (finalPrice * (100 - disccount)) / 100
  } else if (data.price > 5000) {
    disccount = 5
    finalPrice = (finalPrice * (100 - disccount)) / 100
  }

  const priceTip = () => {
    if (data.productRoofType.name === "Vertical Roof") {
      return (
        <>
          {!!disccount && (
            <DisccountTape className="ribbon right">{`${disccount}% Off`}</DisccountTape>
          )}
        </>
      )
    } else if (data.productRoofType.name === "A-Frame Roof") {
      return (
        <>
          {!!disccount && (
            <DisccountTape className="ribbon right">{`${disccount}% Off`}</DisccountTape>
          )}
        </>
      )
    } else {
      return (
        <>
          {!!disccount && (
            <DisccountTape className="ribbon right">{`${disccount}% Off`}</DisccountTape>
          )}
        </>
      )
    }
  }

  return (
    <MediaCard className="media-card">
      <MediaCardThumb className="media-card-thumb">
        {renderImage()}
        <ProductId>{`PB#${data.skuNumber}`}</ProductId>
        <CardLabel>{priceTip()}</CardLabel>
      </MediaCardThumb>
      <MediaCardBody
        className="media-card-body"
        style={{ paddingLeft: "2rem" }}
      >
        <ProductName className="card-title">{data.title}</ProductName>
        {/* <ProductPriceInfo className="product-price-info">
          <ProductLabelText>Building Total:</ProductLabelText>
          <OriginPrice className={disccount ? "disccounted" : ""}>
            ${data.price?.toLocaleString("en-US")}
          </OriginPrice>
          {!!disccount && (
            <DisccountedPrice>
              $
              {finalPrice.toLocaleString("en-US", { minimumFractionDigits: 2 })}
            </DisccountedPrice>
          )}
        </ProductPriceInfo> */}
        <ProductPriceDrop className="price-drop" align="flex-start">
          {data.price < 5000 ? (
            <Link to={`${productUrl}/`} className="anchor">
              <span className="icon">
                <img src={PriceDropIcon} alt="Probuilt Steel Buildings" />
              </span>
              <span className="text">Best Price Inside - View Detail</span>
            </Link>
          ) : (
            <Link to={`${productUrl}/`} className="anchor">
              <span className="icon">
                <img src={PriceDropIcon} alt="Probuilt Steel Buildings" />
              </span>
              <span className="text">Price Drop on This Product - View Details</span>
            </Link>
          )}
        </ProductPriceDrop>
        <ProductInfo className="product-info">
          <ProductDesc className="product-desc">
            <p>{data.productDesc}</p>
          </ProductDesc>
          <Divider />
        </ProductInfo>
        <ProductToolbar className="product-toolbar" ml="-5px" mr="-5px">
          <ProductItemToolbar>
            <a href="tel:8777541818" aria-label="(877) 754-1818">
              <SecondaryButton text="Call Us Now" />
            </a>
          </ProductItemToolbar>
          <ProductItemToolbar>
            <Link to={`${productUrl}/`}>
              <PrimaryOutlineButton text="View Details" />
            </Link>
          </ProductItemToolbar>
        </ProductToolbar>
      </MediaCardBody>
    </MediaCard>
  )
}

export default SearchCard
